@use './common/' as *;

.parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 15px;

    margin-top: 100px;
}

@include mq(tablet) {

    .parent {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(7, 20rem);
        max-width: 1000px;
        margin: auto;

        @include mq(tablet) {
            margin-top: 150px;
        }
    }

    .div1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .div2 {
        grid-area: 1 / 2 / 2 / 4;
    }

    .div3 {
        grid-area: 2 / 1 / 3 / 3;
    }

    .div4 {
        grid-area: 2 / 3 / 3 / 4;
    }

    .div5 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .div6 {
        grid-area: 3 / 2 / 4 / 3;
    }

    .div7 {
        grid-area: 3 / 3 / 4 / 4;
    }

    .div8 {
        grid-area: 4 / 1 / 5 / 2;
    }

    .div9 {
        grid-area: 4 / 2 / 5 / 4;
    }

    .div10 {
        grid-area: 5 / 1 / 7 / 2;
    }

    .div11 {
        grid-area: 5 / 2 / 6 / 3;
    }

    .div12 {
        grid-area: 5 / 3 / 6 / 4;
    }

    .div13 {
        grid-area: 6 / 2 / 7 / 3;
    }

    .div14 {
        grid-area: 6 / 3 / 7 / 4;
    }

    .div15 {
        // grid-area: 7 / 1 / 8 / 2;
        grid-area: 7 / 1 / 8 / 4;
    }

    .div16 {
        grid-area: 7 / 2 / 8 / 3;
    }

    .div17 {
        grid-area: 7 / 3 / 8 / 4;
    }

}

.parent {

    >div,
    a {
        height: 100%;
        min-height: 20rem;
        display: block;
        text-decoration: none;
        color: white;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .box-wrap {
            height: 100%;
            position: relative;
            overflow: hidden;

            .text-wrap {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .7);
                transition: .3s;
                font-family: 'raleway';
                @include position(absolute, 100%, 0, null, null);
                @include flex(null, center, center, null);
                .text{
                    font-family: 'raleway';

                }
            }

            &:hover .text-wrap {
                top: 0;
            }
        }
    }

}

.workpage-inner {
    margin-top: calc(var(--navbar-height) + 1rem);
    padding: var(--full-space);

    .head {
        color: var(--primary-color);

        h3 {
            font-family: playfarir-regular;
            font-size: 2.6rem;

        }

        p {
            margin: var(--full-space) 0;
            font-family: roboto;
            font-size: 1.3rem;
        }
    }

    .body {
        &.workinner{
            .image-section{

                aspect-ratio: 21 / 9 ;
            }
        }
        .image-section {
            margin: var(--full-space) 0;
            aspect-ratio: 1 / 1 ;
            video{
                width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .img-21by9{
            aspect-ratio: 21 / 9;
        }
        .img-1by1{
            aspect-ratio: 1 / 1;
        }
        video {
            width: 100%;
        }
    }

    .footer {
        color: var(--primary-color);

        h5 {
            margin: var(--full-space) 0;
            font-size: 2rem;
            font-family: playfarir-regular;
            font-weight: 400;
        }

        p {
            font-family: roboto;
            font-size: 1.6rem;
        }
    }

    @include mq(tablet) {

        &.grid {
            .body {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 15px;

                .image-section {
                    margin: 0;

                    &:first-child,
                    &:last-child {
                        grid-column: 1 / span 2;
                    }
                }
                .only-3-images{
                    margin: 0;
                    &:first-child{
                        grid-column: 1 / span 2;
                    }
                    &:last-child{
                        grid-column:-2 !important;
                    }
                }
            }
        }
        &.bsb{
            .body {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 15px;

                .image-section {
                    margin: 0;

                    &.w-100 {
                        grid-column: 1 / span 2;
                    }
                }
            }    
        }
    }
}