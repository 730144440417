@use './common/' as *;
.verticle-image{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: var(--navbar-height);
    gap: var(--full-space);
    &.oneplus{
        grid-template-columns: repeat(1,1fr);
        
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .item{
        &:nth-child(3n+1){
            
            grid-column: 1/span 2;
        }
    }
}
.verticle-2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: var(--full-space);
    .verticle{
        width: calc(100% - var(--full-space));
    }
    .horizontal{
        width: 100%;
    }
}
.verticle-3{
    display: flex;
    flex-wrap: wrap;
    .verticle{
        width: 25%;
    }
    .horizontal{
        width: 100%;
    }
}
@media (min-width:768px) {
    .verticle-image{
        grid-template-columns: repeat(3,1fr);
        .item{
            &:nth-child(3n+1){
                grid-row: 1/span 2;
            }
            &:nth-child(10){
                grid-row: 3/span 2;
                grid-column: 3/ span 2;
            }
        }
        &.oneplus{
            grid-template-columns: repeat(3,1fr);
            
        }
    }
    .verticle-2{
        .verticle{
            
            width: calc((100% / 3) - var(--full-space));
        }

    }
  
}