@use './common/' as *;

.service {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 120px;
    @include mq(tablet){
        
        background: url('./../images/service-bg.png') no-repeat;
        background-size:  100%;
        background-position: center;
    }
    .anchorLinkedin{
        text-decoration: none;
    }
    a{
        text-decoration: none;

    }
    &-wrap {
        display: grid;

        @include mq(tablet) {
            grid-template-columns: repeat(4, 1fr);
        }

        
    }

    &-individual {
        padding: var(--full-space);
        color: var(--primary-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
      
        @media (min-width:767px) {
            
            height: 473px;
        }
        a{
            color: var(--primary-color);
            text-decoration: none;
        }
        @include mq(tablet) {

            &:not(:last-child) {
                &::after {
                    content: '';
                    width: 1px;
                    height: 60%;
                    position: absolute;
                    right: 0;
                    top:15%;
                    background-color: var(--primary-color);
                }
            }
            
        &:hover {
            h3 {
                order: 2;

            }

            p {
                height: 105px;
                display: -webkit-box;
                line-clamp: 5;
                -webkit-box-orient: vertical;  
                -webkit-line-clamp: 5;
                overflow: hidden;

            }
        }
        }

        >* {
            font-family: playfarir-bold;
            transition: .3s;
            margin: var(--full-space) 0;
            @media (min-width:767px) {
                
                margin: calc(var(--full-space) * 1.5) 0;
            }
        }

        h3 {
            font-size: 8rem;
            text-align: center;
            font-family: 'Bebas Neue', cursive;
            
        }

        h5 {
            font-size:2.4rem;
            text-align: center;
            margin: 0;
        }

        p {
            font-size: 1.4rem;
            font-family: 'raleway';
            text-align: center;
            @media (min-width:767px) {
                
                text-align: left;
                height: 0;
                overflow: hidden;
            }

        }

    }
}