@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
:root{
    --black-color:#000;
    --white-color:#fff;
    --primary-color:#9E613D;
    --full-space:1.5rem;
    --half-space:calc(var(--full-space) / 2);
    --navbar-height:8rem;
}
// fonts 
@font-face {
    font-family: playfarir-light;
    src: url(../../Fonts/PlayfairDisplay-SemiBold.ttf);
}

@font-face {
    font-family: playfarir-regular;
    src: url(../../Fonts/PlayfairDisplay-Regular.ttf);
}
@font-face {
    font-family: playfarir-medium;
    src: url(../../Fonts/PlayfairDisplay-Medium.ttf);
}
@font-face {    
    font-family: playfarir-bold;
    src: url(../../Fonts/PlayfairDisplay-Bold.ttf);
}

@font-face {
    font-family: playfarir-extra-bold;
    src: url(../../Fonts/PlayfairDisplay-ExtraBold.ttf);
}

$raleway :'Raleway', sans-serif;