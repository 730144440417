.blog {
    margin-top: 150px;
    img {
        width: 100%;
    }
    .two-secton{
        display: grid;
        @media (min-width:768px) {
            
            grid-template-columns: 1fr 3fr;
        }
        justify-content: center;
        align-items: center;
        padding: 30px;
        margin: 50px 0;
    }
    .text-box{
        padding: 30px;
    }
    h3{
        &::after{
            position: absolute;
            top: 110%;
            left: 0;
            content: '';
            width: 30px;
            height: 2px;
            background-color: var(--primary-color);
        }
        position: relative;
        font-size: 4rem;
        color: var(--primary-color);
    }
    p,li{
        font-size: 1.5rem;
        margin: 20px 0;
        color: #555;
    }
}

.blogslider {
}