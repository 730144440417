@use './common/' as *;

.client {
    width: 100%;
    min-height: 100vh;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0; 
    // padding-top: 100px;
    h3{
        text-align: center;
        font-size: 2rem;
        color: #fff;
        margin-top: 8rem;
        margin-bottom: 4rem;
        font-family: playfarir-regular;
        // padding-left: 14px;
    }
    img{
        width: 100%;
    }
    .desktop{
        display: none;
    }
    @media (min-width:768px) {
        // padding-top: 150px;
        .desktop{
            display: block;
        }
        .mobile{
            display: none;
            margin-top: -6rem !important;
        }
        .client {
            justify-content: center;
            align-items: center;
            padding: 30px 0;
                h3{
                    margin-bottom: -6rem !important;
        }
    }

    }

}

