@use './common/' as *;

.about {
    width: 100%;
    min-height: 100vh;
    // overflow: hidden;
        // background-image: linear-gradient(to bo  ttom, var(--white-color) var(--width), var(--primary-color) var(--width));
    position: relative;
    
  padding-top: calc(var(--navbar-height));

  &-wrap{
    min-height: calc(100vh - var(--navbar-height));
    overflow: hidden;
    @include flex(column,space-between,stretch,null);
  }
  h2{
      font:2.6rem/2 $raleway;
      font-weight: 600;
  }
  p{
    font: 1.2rem/1.8 $raleway;
    margin: var(--half-space) 0;
    max-width: 60rem;
  }
  h5{
    font: italic 1.8rem/1.3 $raleway;
  }
  &-top{
    text-align: center;
    flex-basis: 50%;
    display: flex;
    margin-bottom: 3rem;
    padding: 0 var(--full-space);
    h2{
    color:  var(--primary-color);

    }
    p{
    margin: auto;
    color:  var(--primary-color);
    padding: 10px;

    }
  }
  &-bottom{
    background-color: var(--primary-color);
    position: relative;
    margin-top: var(--full-space);
    padding: 2rem;
    color: var(--white-color);
    flex-basis: 50%;
    .inner{
        padding-right: 30rem;
    }
  }
  .photo{
    @include position(absolute,null,5%,2%,null);
    max-width: 30rem;
    img{
        width: 100%;
    }
    
  }
  @media (max-width:767px) {
    height: unset;
    &-bottom{
        .inner{

            padding-right: 5rem;
        }
        .photo{
            max-width: 22rem;
            right: 0rem;
        }
        p{
            max-width: 20rem;
        }
    }
  }
}