.home-slider{
    height: 100vh;
}
.home {
    width: 100%;
    height: 100vh;
    background-image: url('../images/mint-bg-lamp.jpg');
    background-size: cover;
    background-position: 0 -71px;
    background-blend-mode: darken;
    background-color: rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    h2 {
        font: 3rem playfarir-bold;
        text-align: center;
        color: rgba(255,255,255,.8);
        padding: var(--full-space);
    }
    
    @media (max-width:500px) {
        // background-position:-43% -78px ;
        background-position: -73% -78px;
        background-size: auto 120%;
        h2{
            font-size: 2rem;
            margin-top: 0rem;
        }
    }
    &2 {
        background-image: url('../images/brown-bg.jpg');
        
        h2 {
            font-size: 2rem;
            margin-top: 0;
        }
    }
    
}

