@use './common/' as *;

.contact {
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;

    &::after {
        width: 300px;
        height: 110%;
        background-color: var(--primary-color);
        content: '';
        position: absolute;
        right: 10%;
        top: -5px;

        @media (max-width:600px) {
            display: none;
        }

    }

    * {
        font-family: $raleway;
        // color: var(--primary-color);
        color: #b9734a;
    }

    .form {
        width: 100%;
        padding: 30px;

        img {
            width: 100%;
        }

        @media (min-width:600px) {

            .content,
            img {
                display: none;
            }

            width: 35%;
        }

        .content {
            * {
                color: var(--primary-color);
            }
            > *{
                text-align: left;
                justify-content: start;
                flex-direction: row;
            }

        }

        h3 {
            font-size: 3rem;
            padding-bottom: 10px;
            font-weight: 600;
        }

        h4 {
            font-size: 1.8rem;
            font-weight: 400;
        }

        input {
            width: 100%;
            border: 0;
            border-bottom: 1px solid;
            color: var(--primary-color);
            padding: calc(var(--full-space) / 2);
            margin: var(--full-space) 0;
            font-size: 1.4rem;

            &::placeholder {
                color: var(--primary-color);
                font-size: 1.4rem;
            }
        }

        button {
            padding: var(--full-space) calc(var(--full-space) / 2);
            width: 100%;
            margin-top: var(--full-space);
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid;
            border-radius: 20px;

        }
    }

    .content {
        margin-top: 20px;
        margin-right: 10px;

        &>* {
            margin-bottom: 10px;
            text-align: right;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-size: 20px;
        }

        * {
            color: var(--white-color);

        }

        a {
            color: var(--white-color);
            font-size: 1.5rem;
            text-decoration: none;

        }

        .text {
            font-size: 1.5rem;
            margin-right: 5px;
            margin-left: 5px;
        }

        .bold {
            display: none;
            font-family: playfarir-bold;

        }

        .social {
            margin-top: 10px;
            a{
                margin-right: 15px;
            }
            .text {
                color: var(--white-color);
            }
            @media (min-width:768px) {
                a{
                    margin-right: 0;
                    margin-left: 15px;
                }
            }
        }

    }

    .contact-imageside {
        position: absolute;
        top: 50%;
        right: 10%;
        z-index: 2;
        transform: translateY(-50%);
        width: 40%;

        img {

            box-shadow: -10px 10px 5px -5px rgba(0, 0, 0, .5);
            width: 100%;
        }

        @media (max-width:600px) {
            position: unset;
            transform: none;
            padding: 20px;
            display: none;
            img {
                display: none;
            }
        }
    }

    #mail-status {
        p {

            color: black;
            font-size: 1.5rem;
            color: #76c300;
        }
    }
}