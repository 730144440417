@use'./common/index' as *;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.navbar {
    padding: 1rem 2rem;
    height: var(--navbar-height);
    width: 100%;
    z-index: 3;
    @include position(absolute, 0, 0, null, null);
    @include flex(null, space-between, center, null);

    .logo {
        width: 50px;
    }
    .logo-filter-home-page{
        filter: invert(1);
    }
    .logo-filter-other-pages{
        filter: invert(43%) sepia(27%) saturate(1024%) hue-rotate(339deg) brightness(88%) contrast(81%);
    }
    img {
        width: 100%;
    }

    .hamburger {
        width: 3rem;
        height: 3rem;
        cursor: pointer;

        .line {
            width: 100%;
            height: .1rem;
            background-color: var(--white-color);
            margin-bottom: var(--half-space);
            transition: .3s;

            &:nth-child(2),
            &:nth-child(3) {
                width: 70%;
            }
        }

        // &.active{
        //     .line{
        //         &:first-child{
        //             transform:translate(4px, 17px) rotate(47deg);
        //         }
        //         &:nth-child(2){
        //             transform: translate(4px, 7px) rotate(136deg);
        //         }
        //         &:nth-child(3){
        //             transform: translate(7px, -2px) rotate(47deg);
        //         }
        //         &:nth-child(4){
        //             transform:translate(0px, -11px) rotate(136deg);
        //         }
        //     }
        // }
        &-close {
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            @include position(absolute, var(--full-space), var(--full-space), null, null);

            .line {
                width: 100%;
                height: .2rem;
                background-color: var(--primary-color);

                &:first-child {
                    transform: translate(0px, 12px) rotate(45deg);
                }

                &:last-child {
                    transform: translate(0px, 10px) rotate(135deg);

                }
            }
        }
    }
    a{
        text-decoration: none;
    }
    h2 {
        font-size: 2rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: var(--white-color);
    }

    .toggle-state {
        width: 300px;
        background-color: #fff;
        list-style: none;
        transition: .3s;
        height: 100vh;
        overflow: auto;
        padding: var(--full-space);
        padding-top: calc(var(--full-space) * 4);
        @include position(fixed, 0, -100%, null, null);

        &.active {
            right: 0;

        }

        li {
            padding  : var(--full-space) var(--half-space);
            text-align: center;
            &:not(:first-child){

                border-top: 1px solid rgba($color: #9E613D, $alpha: .4) ;
            }
            a {
                color: var(--primary-color);
                font-size: 1.5rem;
                text-decoration: none;
                font-family: playfarir-regular;
            }
        }
        
    }
    .brownlogo{
        display: none;
    }
    .whitelogo{
        display: block;
    }
    &.brown{
        h2{
            color: var(--primary-color);
        }
        .hamburger{
            .line{
                background-color: var(--primary-color);
            }
        }
        .brownlogo{
            display: block;
            filter: invert(1);
        }
        .whitelogo{
            display: none;
        }
    }
    
    &.clientsNav{
        .hamburger{
            .line{
                background-color: var(--white-color);
            }
        }
        h2{
            color: var(--white-color);
        }
        .logo-filter-other-pages{
            filter: invert(1);
        }
    }
    
    &.aboutNav{
        .hamburger{
            .line{
                // background-color: var(--white-color);
            }
        }
    }
    @include mq(tablet) {
        .logo {
            width: 80px;
        }

        .hamburger {
            width: 3rem;
            .line{
                margin-bottom: .5rem;
            }
        }

        h2 {
            font-size: 2rem;
            margin-left: -45px;
        }
    }
}
