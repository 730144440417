@use './common/mixin'as *;
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: playfarir-regular;
}
html{
    font-size: 10px;
    @include mq(tablet){

        font-size: 12px;
    };
    @include mq(desktop){

        font-size: 14px;
    };
    
}
.inner{
    max-width: 1200px;
    margin: auto;
    width: 100% ;
}
